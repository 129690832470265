import { Controller } from "@hotwired/stimulus"
import { Uppy, XHRUpload, DragDrop, ProgressBar, Tus, Informer } from 'uppy'
import "uppy/dist/uppy.min.css";
import '@uppy/progress-bar/dist/style.css'
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

export default class extends Controller {
  connect() {
    const onUploadSuccess = (elForUploadedFiles) => (file, response) => {
      var statusElement = document.querySelector('.example-one .uploaded-files h5')
      if (statusElement !== null) {
        statusElement.remove()
      }

      const url = response.uploadURL
      var fileName
      if (file.name.includes('Saldi') || file.name.includes('saldi') || file.name.includes('bank') || file.name.includes('Bank') || file.name.includes('Rekening') || file.name.includes('rekening') || file.name.includes('KBC')) {
        fileName = "Rekeningen voor regio's vanuit " + file.name + " ingelezen."
      } else if (file.name.includes('sjabloon')) {
        fileName = "Revisie " + file.name + " klaar om toegevoegd te worden. Geef een woordje uitleg en druk op opslaan."
      }
      else {
        // fileName = "323 begrotingslijnen voor " + file.name + " ingelezen."
        fileName = file.name + " ingelezen."
      }

      const li = document.createElement('li')
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.appendChild(document.createTextNode(fileName))
      li.appendChild(a)

      document.querySelector(elForUploadedFiles).appendChild(li)
    }


    const uppy  = new Uppy({ debug: true, autoProceed: true })
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content
    uppy.use(DragDrop, {
          target: '.example-one .for-DragDrop',
          locale: {  strings: {
              dropHereOr: ''
            }},
        })
        //.use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
        .use(ProgressBar, { target: '.example-one .for-ProgressBar', hideAfterFinish: true })
        .on('upload-success', onUploadSuccess('.example-one .uploaded-files ol'))

    if (this.data.get("indirectValue")) {
      uppy.use(ActiveStorageUpload, {
        directUploadUrl: document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
      })
    } else {
      uppy.use(XHRUpload, {
        endpoint: this.data.get("urlValue"),
        method: "POST",
        responseType: 'text',
        headers: { "X-CSRF-Token": csrfToken },
        getResponseData(responseText, response) {
          // process the server response. In this case it's turbo_stream elements
          return responseText;
        }
      })
    }
    if (this.data.get("indirectValue")) { // https://gorails.com/episodes/uppy-with-active-storage
      uppy.on("complete", (result) => {
        console.log(result)

        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('type', 'hidden')
        hiddenField.setAttribute('name', this.data.get("attachmentValue"))
        hiddenField.setAttribute('value', result.successful[0].response.signed_id)
        this.element.appendChild(hiddenField)

        if (this.data.get("commentValue")) {
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute('type', 'hidden')
          hiddenField.setAttribute('name', this.data.get("commentValue"))
          hiddenField.setAttribute('value', "Initiële versie")
          this.element.appendChild(hiddenField)
        }
      })
    }

    this.uppy = window.uppy = uppy; // useful for testing
  }

  disconnect() {
    window.uppy = null;
    this.uppy.close();
  }
}